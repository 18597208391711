import React from "react";
import { Box, Heading, Image, Text } from "grommet";
const carrieIMG = require("../../img/cc.jpg");

export default function Demo() {
  return (
    <Box background="background-back" align="center" pad="large">
      <Heading
        level={1}
        textAlign="center"
        gridArea="problem-header"
        fill="horizontal"
      >
        The Target Demographic
      </Heading>
      <Box width="small" height="small" round="xlarge">
        <Image src={carrieIMG} fit="cover" />
      </Box>
      <Box pad="medium" align="center">
        <Heading level={3} size={"medium"} textAlign="center">
          Carrie Cheng
        </Heading>
        <Text>High income</Text>
        <Text>Has insurance</Text>
        <Text>Has Type 1 Diabetes</Text>
        <Text>
          Female (T1D tends to be harder on females, researchers unsure why)
        </Text>
        <Text>New to Diabetes (needs educations and help with managment)</Text>
      </Box>
    </Box>
  );
}
