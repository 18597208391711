import React from "react";

import Hero from "../components/slides/hero";
import Problem from "../components/slides/problem";
import Solution from "../components/slides/solution";
import Plan from "../components/slides/plan";
import Features from "../components/slides/features";
import Demo from "../components/slides/demographic";
import FutureDemo from "../components/slides/futureDemo";

export default function Home() {
  return (
    <React.Fragment>
      <Hero />
      <Problem />
      <Solution />
      <Plan />
      <Features />
      <Demo />
      <FutureDemo />
    </React.Fragment>
  );
}
