import React from "react";
import { Box, Heading, Image } from "grommet";

const whiteIMG = require("../../img/Sugar Cube.png");
const pinkIMG = require("../../img/Sugar Cube - Pink.png");

export default function Hero() {
  return (
    <Box
      align="center"
      background="background"
      direction="row"
      justify="around"
      pad="large"
    >
      <Box width="small" height="small">
        <Image src={whiteIMG} fit="contain" />
      </Box>
      <Box>
        <Heading
          level={1}
          textAlign="center"
          gridArea="problem-header"
          fill="horizontal"
        >
          Welcome to SugarAlly
        </Heading>
        <Heading
          level={3}
          textAlign="center"
          gridArea="problem-header"
          fill="horizontal"
        >
          your new ally in your T1D journey
        </Heading>
      </Box>
      <Box width="small" height="small">
        <Image src={pinkIMG} fit="contain" />
      </Box>
    </Box>
  );
}
