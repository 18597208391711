import React from "react";
import { Main } from "grommet";
import Switch from "../constants/switch";

import { DIRKFU } from "../constants/theme";

export default function Terms(props) {
  return (
    <Main
      background={{
        color: DIRKFU.global.colors["background"][props.userTheme]
      }}
      pad={{ vertical: "10vh" }}
    >
      <Switch userTheme={props.userTheme} />
    </Main>
  );
}
