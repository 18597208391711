import React from "react";
import styled from "styled-components";

import { Code } from "grommet-icons";
import { Anchor, Box, Footer, Text } from "grommet";
// import { Link } from "react-router-dom";

import { DIRKFU } from "../constants/theme";
import siteMap from "../constants/siteMap";

const StyledAnchor = styled(Anchor)`
  font-weight: 200;
`;

const FooterAnchor = ({ link, ...rest }) => (
  <StyledAnchor href={link} size="small" {...rest} />
);

const FooterContent = () => {
  return siteMap.map(item => (
    <Box gap="small" key={item[0].title}>
      <a href={item[0].link}>
        <Text weight="bold" size="small">
          {item[0].title}
        </Text>
      </a>
      <Box>
        {[1, 2, 3, 4].map(i => (
          <FooterAnchor
            key={`${item[0].title}+${item[i].title}`}
            link={item[i].link}
          >
            {item[i].title}
          </FooterAnchor>
        ))}
      </Box>
    </Box>
  ));
};

const DoubleFooter = props => {
  return (
    <React.Fragment>
      <Footer
        background={{
          color: DIRKFU.global.colors["background-back"][props.userTheme]
        }}
        pad="large"
        direction="row"
        justify="between"
      >
        <FooterContent />
      </Footer>
      <Footer
        background={{
          color: DIRKFU.global.colors["background"][props.userTheme]
        }}
        pad={{ horizontal: "large", vertical: "small" }}
        direction="row"
        justify="between"
      >
        <Box direction="row" gap="small">
          <Code color="brand" />
          <Text alignSelf="center">SugarAlly</Text>
        </Box>
        <Box>
          <Text textAlign="center" size="small">
            © 2020 Copyright
          </Text>
        </Box>
      </Footer>
    </React.Fragment>
  );
};

export default function DirkfuFooter(props) {
  return (
    <React.Fragment>
      <DoubleFooter userTheme={props.userTheme} />
    </React.Fragment>
  );
}
