import React from "react";
import { Box, Chart, Heading, Text } from "grommet";

export default function FutureDemo() {
  return (
    <Box background="background" align="center" pad="large">
      <Heading
        level={1}
        textAlign="center"
        gridArea="problem-header"
        fill="horizontal"
      >
        The Next Demographic
      </Heading>
      <Box pad="medium" align="center">
        <Box pad="medium">
          <Heading level={3} size={"medium"} textAlign="center">
            Age at Diagnosis of T1D
          </Heading>
          <Chart
            bounds={[[0, 11], [0, 9000]]}
            values={[
              { value: [0, 310], label: "< 1" },
              { value: [1, 7162], label: "1-5" },
              { value: [2, 7829], label: "6-10" },
              { value: [3, 5364], label: "11-15" },
              { value: [4, 1633], label: "16-20" },
              { value: [5, 948], label: "21-25" },
              { value: [6, 752], label: "26-30" },
              { value: [7, 561], label: "31-35" },
              { value: [8, 461], label: "36-40" },
              { value: [9, 280], label: "41-45" },
              { value: [10, 199], label: "46-50" },
              { value: [11, 333], label: ">=51" }
            ]}
            aria-label="chart"
          />
        </Box>
        <Heading level={3} size={"medium"} textAlign="center">
          Berry Beng
        </Heading>
        <Text>Parents of Diabetics</Text>
        <Text>Low income</Text>
        <Text>No insurance</Text>
        <Text>Learning what affects their child</Text>
      </Box>
    </Box>
  );
}
