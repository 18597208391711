import React from "react";
import { Box, Heading, Text } from "grommet";

export default function Problem(props) {
  return (
    <Box background="background-back" align="center" pad="large">
      <Heading
        level={1}
        textAlign="center"
        gridArea="problem-header"
        fill="horizontal"
      >
        The Problem
      </Heading>
      <Box gridArea="problem-text" pad={{ vertical: "medium" }} width="xlarge">
        <Text size="xlarge" textAlign="center">
          Diabetics have no way to predicting how exertion, food quantities and
          types, and insulin will affect glucose levels over time. This makes
          balancing glucose levels extremely difficult, which can lead to
          critical health conditions.
        </Text>
      </Box>
      <Box gridArea="problem-ycomb" pad="medium" align="center">
        <Heading level={3} size={"medium"} textAlign="center">
          Problem Categories (Y Combinator)
        </Heading>
        <Text>Popular</Text>
        <Text>Growing</Text>
        <Text>Urgent</Text>
        <Text style={{ textDecoration: "line-through" }}>Expensive</Text>
        <Text>Mandatory</Text>
        <Text>Frequent</Text>
      </Box>
    </Box>
  );
}
