import React from "react";
import { Box, Heading, List } from "grommet";

export default function Features() {
  return (
    <Box background="background" align="center" pad="large">
      <Heading
        level={1}
        textAlign="center"
        gridArea="problem-header"
        fill="horizontal"
      >
        The Features
      </Heading>

      <Box direction="row">
        <Box
          align="center"
          gridArea="solution-option1"
          pad={{ vertical: "medium" }}
          width="xlarge"
        >
          <Box pad={{ horizontal: "medium" }}>
            <Heading
              level={2}
              textAlign="center"
              gridArea="solution-header"
              fill="horizontal"
            >
              MVP
            </Heading>
          </Box>
          <Box pad={{ horizontal: "medium" }} width="large">
            <List
              primaryKey="num"
              secondaryKey="text"
              data={[
                {
                  num: "1.",
                  text:
                    "As a T1D, I want to be able to document what/when I eat, along with the amount/when I inject insulin, so I can see how my glucose levels (GL) are affected"
                },
                {
                  num: "2.",
                  text:
                    "As a T1D, I want to be able to see how my exertion affects my glucose levels so I can better predict how various levels of exertion will affect my GL"
                },
                {
                  num: "3.",
                  text: "Build MVP (Research required data)"
                }
              ]}
            />
          </Box>
        </Box>

        <Box
          align="center"
          gridArea="solution-option2"
          pad={{ vertical: "medium" }}
          width="xlarge"
        >
          <Box pad={{ horizontal: "medium" }}>
            <Heading
              level={2}
              textAlign="center"
              gridArea="solution-header"
              fill="horizontal"
            >
              Phase 2
            </Heading>
          </Box>
          <Box pad={{ horizontal: "medium" }} width="large">
            <List
              primaryKey="num"
              secondaryKey="text"
              data={[
                {
                  num: "1.",
                  text:
                    "As a T1D, I want to be able to correlate patterns between GL,insulin amounts, foods, and exertion, so that I can understand how my GL respond to the different combinations"
                }
              ]}
            />
          </Box>
        </Box>

        <Box
          align="center"
          gridArea="solution-option2"
          pad={{ vertical: "medium" }}
          width="xlarge"
        >
          <Box pad={{ horizontal: "medium" }}>
            <Heading
              level={2}
              textAlign="center"
              gridArea="solution-header"
              fill="horizontal"
            >
              Phase 3
            </Heading>
          </Box>
          <Box pad={{ horizontal: "medium" }} width="large">
            <List
              primaryKey="num"
              secondaryKey="text"
              data={[
                {
                  num: "1.",
                  text:
                    "As a T1D, I want a more accurate representation of the above correlations using the nutritional information of foods and amounts, so I can more accurately predict how the relationship above affects my GL"
                },
                {
                  num: "2.",
                  text: "Automatic glucose tracking & patterns recognition"
                },
                {
                  num: "3.",
                  text: "Data relay & communication with healthcare provider"
                }
              ]}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
