import React from "react";
import { Box, Heading, List, Text } from "grommet";

export default function plan() {
  return (
    <Box background="background-back" align="center" pad="large">
      <Heading
        level={1}
        textAlign="center"
        gridArea="problem-header"
        fill="horizontal"
      >
        The Plan
      </Heading>

      <Box direction="row">
        <Box
          align="center"
          gridArea="solution-option1"
          pad={{ vertical: "medium" }}
          width="xlarge"
        >
          <Box pad="medium">
            <Heading
              level={2}
              textAlign="center"
              gridArea="solution-header"
              fill="horizontal"
            >
              Phase 1
            </Heading>
          </Box>
          <Box pad={{ vertical: "medium", horizontal: "xlarge" }} width="large">
            <Text size="xlarge" textAlign="center" wordBreak="break-word">
              Sunt senserit iis familiaritatem, litteris familiaritatem quo
              senserit, ita o eram quis elit ex aut offendit ea cernantur. Quis
              quibusdam imitarentur, ullamco ea labore ullamco.
            </Text>
          </Box>
          <Box pad={{ vertical: "medium", horizontal: "xlarge" }} width="large">
            <List
              primaryKey="text"
              data={[
                {
                  text: "Target and apply for relevant grants"
                },
                {
                  text: "Customer discovery"
                },
                {
                  text: "Build MVP (Research required data)"
                }
              ]}
            />
          </Box>
        </Box>

        <Box
          align="center"
          gridArea="solution-option2"
          pad={{ vertical: "medium" }}
          width="xlarge"
        >
          <Box pad="medium">
            <Heading
              level={2}
              textAlign="center"
              gridArea="solution-header"
              fill="horizontal"
            >
              Phase 2
            </Heading>
          </Box>
          <Box pad={{ vertical: "medium", horizontal: "xlarge" }} width="large">
            <Text size="xlarge" textAlign="center" wordBreak="break-word">
              Se fore graviterque, fugiat e mandaremus id fore. Ita de aute
              appellat. Pariatur ea mandaremus. Eiusmod ex senserit, te enim
              tamen est occaecat quis.
            </Text>
          </Box>
          <Box pad={{ vertical: "medium", horizontal: "xlarge" }} width="large">
            <List
              primaryKey="text"
              data={[
                {
                  text: "Build customer feedback loops"
                },
                {
                  text: "Iterative improvements"
                },
                {
                  text: "Partnerships"
                },
                {
                  text: "Investment"
                }
              ]}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
