import React from "react";
import { Switch, Route } from "react-router-dom";

// import { PrivateRoute } from "../helpers/privateRoute";
// import Login from "../screens/login";
// import Logout from "../screens/logout";
import Home from "../screens/home";
// import Builds from "../screens/builds";
import Wsiw from "../screens/wsiw";
import Terms from "../screens/terms";
import Support from "../screens/support";

const NavSwitch = props => {
  return (
    <Switch>
      {/* A <Switch> looks through its children <Route>s and
      renders the first one that matches the current URL. */}
      {/*}<PrivateRoute path="/builds" component={Builds} />
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>*/}
      <Route path="/wsiw/terms">
        <Terms appName="wsiw" />
      </Route>
      <Route path="/wsiw/support">
        <Support appName="wsiw" />
      </Route>
      <Route path="/wsiw">
        <Wsiw />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
};

export default NavSwitch;
