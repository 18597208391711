import React from "react";
import { Box, Heading, Image, List, Text } from "grommet";

const predictiveAnayticsIMG = require("../../img/pa-light.png");
const preventIMG = require("../../img/prevent2.png");

export default function Solution() {
  return (
    <Box background="background" align="center" pad="large">
      <Heading
        level={1}
        textAlign="center"
        gridArea="solution-header"
        fill="horizontal"
      >
        The Solution
      </Heading>

      <Box direction="row">
        <Box
          align="center"
          gridArea="solution-option1"
          pad={{ vertical: "medium" }}
          width="xlarge"
        >
          <Box width="small" height="small">
            <Image src={predictiveAnayticsIMG} fit="contain" />
          </Box>
          <Box pad="medium">
            <Heading
              level={2}
              textAlign="center"
              gridArea="solution-header"
              fill="horizontal"
            >
              White label predictive analytics
            </Heading>
          </Box>
          <Box pad={{ vertical: "medium", horizontal: "xlarge" }} width="large">
            <Text size="xlarge" textAlign="center" wordBreak="break-word">
              Sunt senserit iis familiaritatem, litteris familiaritatem quo
              senserit, ita o eram quis elit ex aut offendit ea cernantur. Quis
              quibusdam imitarentur, ullamco ea labore ullamco.
            </Text>
          </Box>
          <Box pad={{ vertical: "medium", horizontal: "xlarge" }} width="large">
            <Heading level={4} size="medium">
              Pros and Cons
            </Heading>
            <List
              primaryKey="icon"
              secondaryKey="text"
              data={[
                {
                  icon: "-",
                  text:
                    "Barrier of entry for success will have to be relatively high for it to be worth while"
                }
              ]}
            />
          </Box>
        </Box>

        <Box
          align="center"
          gridArea="solution-option2"
          pad={{ vertical: "medium" }}
          width="xlarge"
        >
          <Box width="small" height="small">
            <Image src={preventIMG} fit="contain" />
          </Box>
          <Box pad="medium">
            <Heading
              level={2}
              textAlign="center"
              gridArea="solution-header"
              fill="horizontal"
            >
              Target diabetes prevention
            </Heading>
          </Box>
          <Box pad={{ vertical: "medium", horizontal: "xlarge" }} width="large">
            <Text size="xlarge" textAlign="center" wordBreak="break-word">
              Se fore graviterque, fugiat e mandaremus id fore. Ita de aute
              appellat. Pariatur ea mandaremus. Eiusmod ex senserit, te enim
              tamen est occaecat quis.
            </Text>
          </Box>
          <Box pad={{ vertical: "medium", horizontal: "xlarge" }} width="large">
            <Heading level={4} size="medium">
              Pros and Cons
            </Heading>
            <List
              primaryKey="icon"
              secondaryKey="text"
              data={[
                {
                  icon: "+",
                  text: "Possibility to create and capture a new market"
                },
                {
                  icon: "+",
                  text:
                    "Provide forecasting of onset of diabetes, relative to habits"
                },
                {
                  icon: "+",
                  text:
                    "Medicare will cover participation in the CDC’s National Diabetes Prevention Program for those who are eligible"
                }
              ]}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
