import React, { useState, useMemo } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";

import { Box, Grommet } from "grommet";
import { DIRKFU } from "./constants/theme";

import Header from "./components/header";
import Main from "./components/main";
import Footer from "./components/footer";

import { UserContext } from "./contexts/UserContext";
import { CONSTANTS } from "./constants";

const userTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
  ? "light"
  : "light";

// const height = window.innerHeight;
// const width = window.innerWidth;

function App(props) {
  const [user, setUser] = useState({
    id: "",
    token: localStorage.getItem(CONSTANTS.AUTH_TOKEN)
      ? localStorage.getItem(CONSTANTS.AUTH_TOKEN)
      : "",
    prefs: {}
  });

  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  // TODO: should utilize userTheme more that its being passed down to all top level components

  return (
    <UserContext.Provider value={value}>
      <Router>
        <Grommet theme={DIRKFU} full={true}>
          <Box>
            <Header userTheme={userTheme} />
            <Main userTheme={userTheme} />
            <Footer userTheme={userTheme} />
          </Box>
        </Grommet>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
